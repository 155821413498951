import React, { useEffect, useState } from "react"
import axios from "axios"
const HybridReportForm = () => {
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [ErrorMessage, setErrorMessage] = useState("")
  const [Name, setName] = useState("")
  const [Email, setEmail] = useState("")
  const [Phone, setPhone] = useState("")
  const [organization, setOrganization] = useState("")
  const [Message, setMessage] = useState("")
  const [Button, SetButton] = useState(false)
  const [NameError, setNameError] = useState("")
  const [EmailError, setEmailError] = useState("")
  const [PhoneError, setPhoneError] = useState("")
  const [organizationsError, setorganizationsError] = useState("")
  const [referrerLink, setreferrerLink] = useState("")
  const [MessageError, setMessageError] = useState("")
  useEffect(() => {
    if (document.referrer && document.referrer != "") {
      // console.log("Thanks for visiting this site from " + document.referrer)
      setreferrerLink(document.referrer)
    } else {
      // console.log("Direct Visitor")
      setreferrerLink("")
    }
  })
  function validateEmail(email) {
    // Regular expression to check the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email format is valid
    if (!emailRegex.test(email)) {
      return false
    }

    return true
  }
  const handleSubmit = e => {
    e.preventDefault()

    if (Name !== "" && Email !== "" && Phone !== "") {
      setErrorMessage("")
      if (Name.length <= 2) {
        setNameError("Enter Vaild Name")
      } else {
        setNameError("")
      }
      if (!validateEmail(Email)) {
        setEmailError("Please Enter Vaild Email Address")
      } else {
        setEmailError("")
      }
      if (!Phone.match("[0-9]{10}")) {
        setPhoneError("Please Enter Vaild Mobile No")
      } else {
        setPhoneError("")
      }
      if (organization.length <= 2) {
        setorganizationsError("Please Enter Vaild Organization Name")
      } else {
        setorganizationsError("")
      }
      if (
        Name.length >= 2 &&
        validateEmail(Email) &&
        Phone.match("[0-9]{10}") &&
        organization.length >= 2
      ) {
        axios
          .post(`https://gofloaters.firebaseapp.com/website/hybridReportForm`, {
            name: Name,
            email: Email,
            phone: "+91" + Phone,
            organization: organization,
            referrerLink: referrerLink,
          })
          .then(res => {
            setSuccessMessage("show")
            setName("")
            setEmail("")
            setPhone("")
            setOrganization("")
            SetButton(false)
          })
      }
    } else {
      setNameError("Enter Vaild Name")
      setEmailError("Please enter vaild email address")
      setPhoneError("Please enter vaild mobile no")
      setorganizationsError("Please enter vaild organization")
    }
  }
  // useEffect(() => {
  //   if (
  //     Name.length > 2 &&
  //     Phone.match("[0-9]") &&
  //     Phone.length >= 10 &&
  //     validateEmail(Email)
  //   ) {
  //     SetButton(false)
  //   } else {
  //     SetButton(true)
  //   }
  // })
  return (
    <div className="row">
      <div className="col-md-7">
        <div style={{ padding: "5px 15px" }}>
          <br></br>
          <h3 style={{ fontWeight: "bold" }} className="section-header-title">
            The future of work is here, and it's called hybrid work!
          </h3>
          <p>
            We have analyzed over 40,000+ customer interactions, identified data
            patterns and correlated them with the conversations that we have had
            with our users and leaders to come up with this report. This report
            is based on live booking data from the GoFloaters WorqFlexi
            platform.
            <br />
            <br />
            The report offers a comprehensive overview of this exciting trend,
            giving you key insights and trends to help you understand how hybrid
            work is changing the game.
            <br />
            <br />
            With our report, organizations and managers can learn about the
            benefits and challenges of hybrid work, how companies are
            implementing hybrid models, the impact on employee productivity and
            engagement, and best practices for managing hybrid teams.
            <br />
            <br />
            Whether you are a business, HR or Admin Leader, people manager, or a
            remote worker, or just someone interested in the future of work, our
            report has everything you need to know.
            <br />
            <br /> Join us as we explore the state of hybrid work and discover
            insights that will help you thrive in the new world of work.
            <br />
            <br /> Don't wait, download the report for free now to get started!
            <br />
            <br /> What has been featured?
            <br />
            <br />
            <ul style={{ fontWeight: "bold" }} className="hybridList">
              <li>
                <span>
                  Trends in workspace utilization 2022 & highlights of 2023
                </span>
              </li>
              <li>
                <span>Trends in Meeting Rooms bookings </span>
              </li>
              <li>
                <span>Gender-based patterns in workspace adoption</span>
              </li>
              <li>
                <span>Region Wise usage patterns </span>
              </li>
              <li>
                <span>What teams want - Offsites and team gatherings?</span>
              </li>
              ... and more
            </ul>
          </p>
        </div>
      </div>
      <div className="col-md-5 teamForm ">
        <div className="hydridForm">
          {SuccessMessage === "hide" ? (
            <div id="signup" className="section-header-title">
              <h4 className="text-center">Download the insightful report </h4>
              <h5 className="text-center">It's free!</h5>
              <form>
                <div className="form-group">
                  <label htmlFor="Name">
                    Your Name *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {NameError}
                    </span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={Name}
                    onChange={e => {
                      setName(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    Email Address *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {EmailError}
                    </span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={Email}
                    onChange={e => {
                      setEmail(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Phone">
                    Phone *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {PhoneError}
                    </span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    value={Phone}
                    onChange={e => {
                      var phone = e.currentTarget.value
                      if (!phone.match("[0-9]{10}")) {
                        setPhone(e.currentTarget.value)
                      } else {
                        setErrorMessage("")
                        setPhone(e.currentTarget.value)
                      }
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Name">
                    Your Organization *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {organizationsError}
                    </span>
                  </label>
                  <input
                    type="text"
                    name="organization"
                    className="form-control"
                    value={organization}
                    onChange={e => {
                      setOrganization(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>

                <button
                  onClick={handleSubmit}
                  disabled={Button}
                  className="btn btn-default"
                >
                  Download the Free Report
                </button>
              </form>

              {ErrorMessage === "" ? (
                ""
              ) : (
                <p style={{ color: "red", fontWeight: "bold" }}>
                  <br />

                  {ErrorMessage}
                </p>
              )}
            </div>
          ) : (
            <div>
              <p style={{ color: "red", fontWeight: "bold" }}>{ErrorMessage}</p>
              <div className={"message " + SuccessMessage}>
                Thank you for submitting your form.
                <br></br>
                <br /> You can download the report here 👇
                <br></br>
                <br></br>
                <a
                  className="teamGetStarted"
                  target="_blank"
                  href="https://assets.gofloaters.com/worqpass/GoFloaters%20-%20The%20State%20of%20Hybrid%C2%A0Work.pdf"
                  download
                >
                  Hybrid Work Report
                </a>
                <br></br>
                <br />
                <span
                  style={{ fontSize: "12px", lineHeight: "14px !important" }}
                >
                  {" "}
                  Catch up on our trending hybrid-remote trends and flexible
                  work narratives. Create your own rulebook of flexiwork
                  <br></br>
                  <br></br>
                  <a
                    target="_blank"
                    rel="no-follow"
                    href="https://youtube.com/playlist?list=PLiMbyD5zSC3-TwrMfrsLzKfbpIxrALQBI"
                    className=""
                  >
                    Watch now
                  </a>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HybridReportForm
